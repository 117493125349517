import { useEffect, useState } from "react";
import LampService from "./lamp-service";

export const useLamp = (dt) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [slots, setSlots] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getFilterData = async () => {
      setIsLoading(true);
      try {
        const data = await Promise.all([
          LampService.getBrands(),
          LampService.getTypes(),
          LampService.getSlots(),
        ]);
        setBrands(data[0].data?.data);
        setTypes(data[1].data?.data);
        setSlots(data[2].data?.data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    getFilterData();
  }, []);

  useEffect(() => {
    const filterProducts = async () => {
      try {
        setIsProductsLoading(true);
        const { data } = await LampService.filterProducts(dt);
        setProducts(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsProductsLoading(false);
      }
    };
    filterProducts();
  }, [dt]);

  return { isLoading, brands, types, slots, products, isProductsLoading };
};
