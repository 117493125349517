import React , {useState,useEffect} from 'react';
import {Container, Row , Col} from 'react-bootstrap';
import '../../assets/css/home/style.css';
import TelImg from '../../assets/img/Footer/Phone.png';
import GmailImg from '../../assets/img/Footer/gmail.png';
import AddressImg from '../../assets/img/Footer/Address.png';
import {FormattedMessage} from "react-intl";
import {FaFacebookF} from "@react-icons/all-files/fa/FaFacebookF";
import {FaInstagram} from "@react-icons/all-files/fa/FaInstagram";
import {FaYoutube}  from "@react-icons/all-files/fa/FaYoutube";
import axios from 'axios';
import NET from '../Url/url';
import { useIntl } from 'react-intl';
import Work from "./work";
function Footer(){
    const [caron_data, setcaron_data] = useState([]);
    useEffect(() => {
        axios.get(`${NET.APP_URL}/api/caron_data`).then(
            res => setcaron_data(res.data),

        )
    }, [])
    console.log(caron_data)

    const [caron_about, setcaron_about] = useState([]);
    useEffect(() => {
        axios.get(`${NET.APP_URL}/api/caron_about`).then(
            res => setcaron_about(res.data),
            
        )
    }, [])
    console.log(caron_about)
    const intl = useIntl()

    return (
        <>
            <Container fluid className={'section_for_footer'}>
                <Container>
                    <Row>
                        {/* <Col xl={8} lg={8}> */}
                            {/* <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.4139040724817!2d44.486282314752465!3d40.133591080735755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdd0501a9c9df3372!2zNDDCsDA4JzAwLjkiTiA0NMKwMjknMTguNSJF!5e1!3m2!1sen!2s!4v1640940283888!5m2!1sen!2s"
                                width="100%"
                                height="307px"
                                frameBorder="0"
                                allowFullScreen=""
                                loading="lazy">

                            </iframe> */}
                        {/* </Col> */}
                        {
                                    caron_about && caron_about.map(
                                        (items, index) => {
                                            if(items.location == "about_footer")        
                                            return    <Col xl={8} lg={8}>
                                                    <img 
                                                    src={`${NET.APP_URL}/public/image/${items.about_images}`} 
                                                    className={'img-fluid w-100'} 
                                                  />
                                            </Col>
                    //                         <div id={'home-section-about_about'}>
                    //                             <Container fluid  className={'m-0 about_height_container'}>
                    //                         <Row key={index}>
                    //                             <Col  xl={{span:3,offset:2}} 
                    //                                 lg={{span:3,offset:2}} 
                    //                                 md={{span:3,offset:2}} 
                    //                                 sm={{span:4,offset:3}} 
                    //                                 xs={{span:6,offset:3}} 
                    //                                 className={'left_section'}
                    //                             >
                    //                               <img 
                    //                                 src={`${NET.APP_URL}/public/image/${items.about_images}`} 
                    //                                 className={'img-fluid w-100'} 
                    //                                 alt={items.title}
                    //                               />  
                    //                             </Col>
                    //                             <Col 
                    //                                 xl={{span: 5, offset: 1}} 
                    //                                 lg={{span:5,offset:1}} 
                    //                                 md={{span:5,offset:1}} 
                    //                                 sm={{span:6,offset:3}} 
                    //                                 xs={{span:10,offset:1}} 
                    //                                 className={'right_section'}
                    //                             >
                    //                                 <h3>
                    //                                     <FormattedMessage id={'nav_second'} />
                    //                                 </h3>
                    //                                 <p>
                    //                                     {items[`description_${intl.locale}`]}
                    //                                 </p>
                    //     <Link to={'/about'} className={'prev-link'}>
                    //         <FormattedMessage id={'see_more'} />
                    //     </Link>
                    // </Col>                      
                    //                         </Row>    
                    //                         </Container>
                    //                         </div>
                                        }
                                    )
                                }
                     
                        <Col xl={4} lg={4} className={'right-section'}>
                            <div>
                                <Work />
                            </div>
                            <div className={'right_section_title'}>
                                <FormattedMessage id={'nav_eight'} />
                            </div>
                            <div className={'right_section_title'}>
                               {
                                   caron_data && caron_data.slice(0,1).map(
                                       (item,index) => {
                                           return <ul>
                                           <li>
                                               <img src={TelImg} alt=""/>
                                               <a href={`tel:${item.tel}`}>
                                                   { item.tel }
                                               </a>
                                           </li>
                                           <li>
                                               <img src={GmailImg} alt=""/>
                                               <a href={`mailto:${item.email}`}>
                                                   {item.email}
                                               </a>
                                           </li>
                                           <li>
                                               <img src={AddressImg} alt=""/>
                                               <a href="#">
                                                   {item[`address_${intl.locale}`]}
                                               </a>

                                           </li>
                                           <li>
                                               <a href={item.face_link} target={'_blank'} className={'icon_social'}>
                                                   <FaFacebookF />
                                               </a>
                                               <a href={item.insta_link} target={'_blank'} className={'icon_social'}>
                                                   <FaInstagram />
                                               </a>
                                               <a href={item.youtube_link} className={'icon_social'}>
                                                    <FaYoutube />
                                               </a>
                                           </li>
                                       </ul>
                                       }
                                   )
                               }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}
export default Footer
