import { $api } from "..";

export default class SnowService {
  static getBrands() {
    return $api.get("/snow/brands");
  }

  static filterProducts(filter) {
    return $api.get("/snow/products_filter", { params: filter });
  }
}
