import React , {useState,useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import '../../assets/css/header/style.css';
import NET from '../Url/url';
import axios from 'axios';
export default function HomeSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 3000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical:true,
        fade:true
    };
    const [home_carousel_images, sethome_carousel_images] = useState([]);
    useEffect(() => {
        axios.get(`${NET.APP_URL}/api/home_carousel_images`).then(
            res => sethome_carousel_images(res.data),
            
        )
    }, [])
    console.log(home_carousel_images)
    return (
        <Container fluid id={'section_for_carousel'}>
            <Row>
                <Col xl={12} id={'padding_none'}>
                    <Slider {...settings}>
                                {
                                    home_carousel_images && home_carousel_images.map(
                                        (item, index) => {
                                                return <div key={index}  className="img-fluid">
                                                        <img 
                                                            src={`${NET.APP_URL}/public/image/${item.carousel_images}`} 
                                                            className={'img-fluid w-100'} 
                                                            alt={ item.carousel_images_title }/>
                                                    </div>
                                                

                                        }
                                    )
                                }

                    </Slider>
                </Col>
            </Row>
        </Container>
    );
}