import React, {useState} from 'react';
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import "../../assets/css/news/style.css";
import PartnerImg from "../../assets/img/BecomePartner/partner.jpg";
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import {CSSTransition} from "react-transition-group";

function sendEmailPartner(e) {
    e.preventDefault();

    emailjs.sendForm('service_4uwaf5j', 'template_c86cu5n', e.target, 'user_SEjLQG67dF8MSaH7z3dwW')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    e.target.reset();

}


export default function Work(){
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
    const setModalIsOpen1ToTrue = () => {
        setModalIsOpen1(true)
    }

    const setModalIsOpen1ToFalse = () => {
        setModalIsOpen1(false)
    }
    const modalStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,.6)',
        },
    };
    return(
        <>
            <Container className={'section_for_become_partner1'}>

                <Row className={'row_for_become_partners'}>
                    <div className={'section_for_partner_modal'}>
                        <div className={'partner_modal_btn'}>
                            <Button onClick={setModalIsOpen1ToTrue}>
                                <FormattedMessage id={'become_partner'}  />
                            </Button>
                            <CSSTransition
                                in={setModalIsOpen1ToTrue}
                                timeout={300}
                                classNames="dialog"
                            >
                                <Modal
                                    closeTimeoutMS={500}
                                    isOpen={modalIsOpen1}
                                    style={modalStyles}
                                >
                                    <div className={'div_for_btn_close'}>
                                        <button className={'close_btn'} onClick={setModalIsOpen1ToFalse} style={{ float: 'right' , background:'transparent', border:'none' , color:"#ffae03" }} >X</button>
                                    </div>
                                    <Container className={'mt-3'}>
                                        <Row className={'row_for_modal_title'}>
                                            <Col xl={{span:10,offset:1}} className={'title'}>
                                                <FormattedMessage id={'form_value'} />
                                                <a href="mailto:group.caron@gmail.com">group.caron@gmail.com</a>
                                            </Col>
                                        </Row>
                                        <Row className={'row_for_form'}>
                                            <Col xl={{span:10,offset:1}}>
                                                <Form onSubmit={sendEmailPartner}>
                                                    <Form.Group>
                                                        <Form.Label for='name_lastname'>
                                                            <FormattedMessage id={'name_lastname'} />
                                                        </Form.Label>
                                                        <Form.Control type='text' id={'name_lastname'} required placeholder={'Անի Ադամյան'} name={'partner_name_lastname'}  />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for='for_phone'>
                                                            <FormattedMessage id={'label_tel'} />
                                                        </Form.Label>
                                                        <Form.Control type='number' id={'for_phone'} required placeholder={'+374 00 00 00 00'} name={'partner_number'} />
                                                    </Form.Group>

                                                    <Form.Group controlId='formBasicEmail'>
                                                        <Form.Label for='for_email'>
                                                            <FormattedMessage id={'label_email'} />
                                                        </Form.Label>
                                                        <Form.Control type='email' id={'for_email'} required placeholder={'example@mail.ru'} name={'partner_email'} />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Կրթություն</Form.Label>
                                                        <Form.Control as="select" id={'for_education'} name={'education'} required>
                                                            <option value="Միջնակարգ">Միջնակարգ</option>
                                                            <option value="Բակալավր">Բակալավր</option>
                                                            <option value="Մագիստրոս">Մագիստրոս</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Հաստիք</Form.Label>
                                                        {/* <Form.Control as="select" id={'for_education'} name={'worker'} required>
                                                            <option value="ՊահեստիԲանվոր">Պահեստի Բանվոր</option>
                                                            <option value="Առաքիչ">Առաքիչ</option>
                                                            <option value="ՎաճառքիՄենեջեր">Վաճառքի Մենեջեր</option>
                                                            <option value="ՕֆիսայինԱշխատակից">Օֆիսային Աշխատակից</option>
                                                        </Form.Control> */}
                                                       <Form.Control type='text' id={'for_email'} required placeholder={'Հաստիք'} name={'worker'} /> 
                                                    </Form.Group>
                                                    {/*<Form.Group controlId='formBasicEmail'>*/}
                                                    {/*    <Form.Label for='for_email'>*/}
                                                    {/*        <FormattedMessage id={'label_address'} />*/}
                                                    {/*    </Form.Label>*/}
                                                    {/*    <Form.Control type='text' required id={'for_email'}  placeholder={'Ք. Երևան Կոմիտաս 26'} name={'partner_address'} />*/}
                                                    {/*</Form.Group>*/}
                                                    {/*<Form.Group controlId='formBasicEmail'>*/}
                                                    {/*    <Form.Label for='for_email'>*/}
                                                    {/*        <FormattedMessage id={'label_hvhh'} />*/}
                                                    {/*    </Form.Label>*/}
                                                    {/*    <Form.Control type='text' id={'for_email'} required placeholder={'02020202'} name={'partner_hvhh'} />*/}
                                                    {/*</Form.Group>*/}
                                                    {/*<Form.Group>*/}
                                                    {/*    <Form.Label for={'for_education'}>*/}
                                                    {/*        <FormattedMessage id={'label_type'} />*/}
                                                    {/*    </Form.Label>*/}
                                                    {/*    <Form.Control as="select" id={'for_education'} name={'partner_type'}>*/}
                                                    {/*        <option value="Ավտոպահեստամասեր">*/}
                                                    {/*            Ավտոպահեստամասեր*/}
                                                    {/*        </option>*/}
                                                    {/*        <option value="Մթերային">*/}
                                                    {/*            Մթերային Խանութ*/}
                                                    {/*        </option>*/}
                                                    {/*        <option value="Ավտոտեխսպասարկում">*/}
                                                    {/*            Ավտոտեղսպասարկում*/}
                                                    {/*        </option>*/}
                                                    {/*    </Form.Control>*/}
                                                    {/*</Form.Group>*/}
                                                    <div className={'partner_modal_btn'}>
                                                        <Button type={'submit'}>
                                                            <FormattedMessage id={'form_submit'}  />
                                                        </Button>
                                                    </div>

                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </CSSTransition>
                        </div>
                    </div>

                </Row>
            </Container>
        </>
    )
}
