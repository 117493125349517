import React from 'react';
import {Container,Row,Col} from "react-bootstrap";

function Knopka(){
    return(
        <>
            <h1 className={'text-light'}>
                Knopka
            </h1>
        </>
    )
}
export default Knopka