import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Pages/Components/ScrollToTop";
import Header from "./Pages/Components/header";
import Home from "./Pages/Components/home";
import About from "./Pages/Components/about";
import Product from "./Pages/Components/product";
import Representation from "./Pages/Components/representation";
import News from "./Pages/Components/news";
import Partner from "./Pages/Components/partner";
import BecomePartner from "./Pages/Components/becomePartner";
import Footer from "./Pages/Components/footer";
import Lamp from "./Pages/Components/ProductComponents/lamp";
import Hoter from "./Pages/Components/ProductComponents/hoter";
import Chotk from "./Pages/Components/ProductComponents/chotk";
import Snow from "./Pages/Components/ProductComponents/snow";
import Qimia from "./Pages/Components/ProductComponents/qimia";
import Interier from "./Pages/Components/ProductComponents/Interier";
import Knopka from "./Pages/Components/ProductComponents/knopka";
import BrandsMap from './Pages/Components/BrandsMap';
import NewsList from "./Pages/Components/newsList";
function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          {/* <Route exact path="/product" element={<Product />} /> */}
          <Route path="/representation" element={<Representation />}>
            <Route
              index
              element={<Navigate to="/representation/lamp" replace />}
            />
            <Route path="lamp" element={<Lamp />} />
            <Route path="hoter" element={<Hoter />} />
            <Route path="chotk" element={<Chotk />} />
            <Route path="snow" element={<Snow />} />
            <Route path="qimia" element={<Qimia />} />
            <Route path="interier" element={<Interier />} />
            <Route path="home" element={<Home />} />
            <Route path="knopka" element={<Knopka />} />
          </Route>

          <Route exact path="/BrandsMap/:id" element={ <BrandsMap />} />

          <Route exact path="/news" element={<News />} />
          <Route exact path="/NewsList/:id" element={<NewsList />} />

          <Route exact path="/partner" element={<Partner />} />
          <Route exact path="/becomepartner" element={<BecomePartner />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
