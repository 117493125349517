import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../../assets/css/representation/style.css";
import { useIntl } from "react-intl";
import NET from "../Url/url";

function NewsList() {
  const [caron_news, setcaron_news] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/caron_news`)
      .then((res) => setcaron_news(res.data));
  }, []);
  const [pre_news, setpre_news] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/pre_news`)
      .then((res) => setpre_news(res.data));
  }, []);
  const intl = useIntl();
  let { id } = useParams();
  return (
    <>
      <Container className={"mt-5"}>
        {caron_news &&
          caron_news.map((item, index) => {
            if (item.id == id)
              return (
                <>
                  <Row key={index}>
                    <Col xl={4}>
                      <div className="news_img">
                        <img
                          src={`${NET.APP_URL}/public/image/${item.news_images}`}
                          className={"img-fluid"}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xl={8}>
                      <div className="news_title text-start pb-0">
                        {new Date(item.created_at).toLocaleDateString()}
                      </div>
                      <div className="news_title">
                        {item[`title_${intl.locale}`]}
                      </div>
                      <div
                        className="news_text"
                        dangerouslySetInnerHTML={{
                          __html: item[`description_${intl.locale}`],
                        }}
                      >
                        {/* {item[`description_${intl.locale}`]} */}
                      </div>
                    </Col>
                  </Row>
                  {pre_news &&
                    pre_news.map((itm, index) => {
                      if (item.news_unqiue == itm.pre_unique)
                        return (
                          <Row className={"mt-5"}>
                            <Col xl={4}>
                              <div className="news_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${itm.pre_images}`}
                                  className={"img-fluid"}
                                  alt=""
                                />
                              </div>
                            </Col>
                            <Col xl={8}>
                              <div className="news_text">
                                {itm[`desc_${intl.locale}`]}
                              </div>
                            </Col>
                          </Row>
                        );
                    })}
                </>
              );
          })}
      </Container>
    </>
  );
}
export default NewsList;
