import { $api } from "..";

export default class FreshenerService {
  static getBrands() {
    return $api.get("/freshener/brands");
  }

  static getTypes() {
    return $api.get("/freshener/types");
  }

  static getSmells() {
    return $api.get("/freshener/smells");
  }

  static filterProducts(filter) {
    return $api.get("/freshener/products_filter", { params: filter });
  }
}
