import { Typography } from "@material-ui/core";
import React, { memo } from "react";
import { Image } from "react-bootstrap";

const ProductItem = ({ title, image, description }) => {
  return (
    <div>
      <div>
        <Image width={120} height="auto" src={image} />
      </div>
      <div>
        <Typography>{title}</Typography>
      </div>
      <div>
        <Typography variant="p">{description}</Typography>
      </div>
    </div>
  );
};

export default memo(ProductItem);
