import React ,{useState,useEffect} from "react";
import { NavLink, Outlet , Link} from "react-router-dom";
import { Container, Row, Col,  Nav, NavDropdown } from "react-bootstrap";
import NET from "../Url/url";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import "../../assets/css/representation/style.css";
import axios from 'axios';
import { Tabs,TabList ,Tab ,TabPanel } from 'react-tabs';
import { Button, Modal } from "react-bootstrap";
import BrandsModal from './BrandsMap';
import ProductsModal from "./BrandsMap";
import {useParams ,useHistory} from 'react-router-dom';
function Representation({match}) {
  const [home_brand_images, sethome_brand_images] = useState([]);
  const [home_brand_new, sethome_brand_new] = useState([]);
  useEffect(() => {
      axios.get(`https://admin.caron.am/api/home_brand_images`).then(
          res => sethome_brand_images(res.data),
          
      )
  }, [])
  useEffect(() => {
      axios.get(`https://admin.caron.am/api/new_brands`).then(
          res => sethome_brand_new(res.data),
          
      )
  }, [])
  console.log(home_brand_images)
  const {id} = useParams();
  const intl = useIntl()

  return (
    <Container fluid id={"big_section_parts"}>
      <Container id={"big_section_title"}>
        <Row>
          <Col xl={12} className={"title"}>
            <FormattedMessage id={"nav_forth"} />
          </Col>
          <Col xl={12} className={"description"}>
            {
              home_brand_new.map((itm,index) => {
                return <div>
                  {itm[`desc_${intl.locale}`]}
                </div>
              })
            }
            
          </Col>
        </Row>
      </Container>
      <Container fluid id={'representation_section_for_home'}>
        <Container>
          <Row xl={{span:12,offset:0}} className={''}>
          {/* <div className="circle_brand"> */}
             {home_brand_images.map((item,index) => {
                 return <Col xl={2} lg={2} md={3} sm={6} xs={6} className={'brand-img'}>
                  
                 <Link to={`/BrandsMap/${item.id}`}>
          <img 
              src={`${NET.APP_URL}/public/image/${item.image}`} 
              className={'img-fluid'}
              alt=''
              // width='50px'
              // className="ms-5" 
            />
          </Link>
          </Col>
})}
      {/* </div> */}
      


          </Row>
        </Container>
      </Container>
     
      {/* <Container>
        <Row>
          <Col xl={12}>
            <Container fluid id={"representation_section_tab"}>
              <Tab.Container defaultActiveKey={"lamp"}>
                <Row>
                  <Container>
                    <Row>
                      <Col xl={12}></Col>
                    </Row>
                  </Container>
                  <Col xl={3} lg={3}>
                    <div>
                      <Nav className={"computer-size-navbar"}>
                        <NavDropdown.Item title={""} eventKey={"lamp"}>
                          <NavLink
                            to="/representation/lamp"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"left_navbar_lamp"} />
                          </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item title={""} eventKey={"hoter"}>
                          <NavLink
                            to="/representation/hoter"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"left_navbar_hoter"} />
                          </NavLink>
                        </NavDropdown.Item>

                        <NavDropdown.Item title={""} eventKey={"chotk"}>
                          <NavLink
                            to="/representation/chotk"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"left_navbar_chotk"} />
                          </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item title={""} eventKey={"snow"}>
                          <NavLink
                            to="/representation/snow"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"left_navbar_hoter_chotk"} />
                          </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item title={""} eventKey={"qimia"}>
                          <NavLink
                            to="/representation/qimia"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"left_navbar_hoter_qimia"} />
                          </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item title={""} eventKey={"interier"}>
                          <NavLink
                            to="/representation/interier"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"left_navbar_ail"} />
                          </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item title={""} eventKey={"home"}>
                          <NavLink
                            to="/representation/home"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"left_navbar_home"} />
                          </NavLink>
                        </NavDropdown.Item>
                        <NavDropdown.Item title={""} eventKey={"knopka"}>
                          <NavLink
                            to="/representation/knopka"
                            style={{
                              border: "none",
                              textDecoration: "none",
                            }}
                            className={(d) => (d.isActive ? "active" : "")}
                          >
                            <FormattedMessage id={"knopka"} />
                          </NavLink>
                        </NavDropdown.Item>
                      </Nav>
                      <Nav
                        className={"flex-column left-side-nav p-0"}
                        id={"tab-mobile-size"}
                      >
                        <NavDropdown
                          title={<FormattedMessage id={"product_choose"} />}
                          id="collasible-nav-dropdown"
                        >
                          <Nav.Item className={"dropdaowns-menu"}>
                            <NavDropdown.Item
                              eventKey={"lamp"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage id={"left_navbar_lamp"} />
                            </NavDropdown.Item>
                          </Nav.Item>
                          <Nav.Item className={"dropdaowns-menu"}>
                            <NavDropdown.Item
                              eventKey={"hoter"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage id={"left_navbar_hoter"} />
                            </NavDropdown.Item>
                          </Nav.Item>
                          <Nav.Item className={"dropdaowns-menu"}>
                            <NavDropdown.Item
                              eventKey={"chotk"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage id={"left_navbar_chotk"} />
                            </NavDropdown.Item>
                          </Nav.Item>

                          <Nav.Item
                            className={"dropdaowns-menu"}
                            id="collasible-nav-dropdown-other"
                          >
                            <NavDropdown.Item
                              eventKey={"snow"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage
                                id={"left_navbar_hoter_chotk"}
                              />
                            </NavDropdown.Item>
                          </Nav.Item>

                          <Nav.Item
                            className={"dropdaowns-menu"}
                            id="collasible-nav-dropdown-other"
                          >
                            <NavDropdown.Item
                              eventKey={"qimia"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage
                                id={"left_navbar_hoter_qimia"}
                              />
                            </NavDropdown.Item>
                          </Nav.Item>

                          <Nav.Item
                            className={"dropdaowns-menu"}
                            id="collasible-nav-dropdown-other"
                          >
                            <NavDropdown.Item
                              eventKey={"interier"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage id={"left_navbar_ail"} />
                            </NavDropdown.Item>
                          </Nav.Item>

                          <Nav.Item
                            className={"dropdaowns-menu"}
                            id="collasible-nav-dropdown-other"
                          >
                            <NavDropdown.Item
                              eventKey={"home"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage id={"left_navbar_home"} />
                            </NavDropdown.Item>
                          </Nav.Item>
                          <Nav.Item
                            className={"dropdaowns-menu"}
                            id="collasible-nav-dropdown-other"
                          >
                            <NavDropdown.Item
                              eventKey={"knopka"}
                              id="collasible-nav-dropdown"
                            >
                              <FormattedMessage id={"knopka"} />
                            </NavDropdown.Item>
                          </Nav.Item>
                        </NavDropdown>
                      </Nav>
                    </div>
                  </Col>
                  <Col xl={9} lg={9} className={"right_content_tabs"}>
                    <Outlet />
                  </Col>
                </Row>
              </Tab.Container>
            </Container>
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
}
export default Representation;
