import { useEffect, useState } from "react";
import BrushService from "./brush-service";

export const useBrush = (dt) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getFilterData = async () => {
      setIsLoading(true);
      try {
        const data = await Promise.all([
          BrushService.getBrands(),
          BrushService.getTypes(),
        ]);
        setBrands(data[0].data?.data);
        setTypes(data[1].data?.data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    getFilterData();
  }, []);

  useEffect(() => {
    const filterProducts = async () => {
      try {
        setIsProductsLoading(true);
        const { data } = await BrushService.filterProducts(dt);
        setProducts(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsProductsLoading(false);
      }
    };
    filterProducts();
  }, [dt]);

  return { isLoading, brands, types, products, isProductsLoading };
};
