import { useEffect, useState } from "react";
import SnowService from "./snow-service";

export const useSnow = (dt) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getFilterData = async () => {
      setIsLoading(true);
      try {
        const { data } = await SnowService.getBrands();
        setBrands(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    getFilterData();
  }, []);

  useEffect(() => {
    const filterProducts = async () => {
      try {
        setIsProductsLoading(true);
        const { data } = await SnowService.filterProducts(dt);
        setProducts(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsProductsLoading(false);
      }
    };
    filterProducts();
  }, [dt]);

  return { isLoading, brands, products, isProductsLoading };
};
