import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../assets/css/about/style.css";
import { FormattedMessage } from "react-intl";
import A1IMG from "../../assets/img/About/a-1.webp";
import axios from "axios";
import { useIntl } from "react-intl";
import NET from "../Url/url";

export default function About() {
  const [caron_about, setcaron_about] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/caron_about`)
      .then((res) => setcaron_about(res.data));
  }, []);
  console.log(caron_about);
  const intl = useIntl();
  return (
    <>
      <Container className={"about_section"}>
        <Row>
          <Col xl={12} className={"title_section mb-5"}>
            <h3>
              <FormattedMessage id={"nav_second"} />
            </h3>
            {caron_about &&
              caron_about.slice(0, 1).map((item, indx) => {
                if (item.location == "home_page")
                  return <p key={indx}>{item[`description_${intl.locale}`]}</p>;
              })}
          </Col>
        </Row>
        {caron_about &&
          caron_about.map((items, index) => {
            if (items.location == "about_page")
              return index % 2 == 0 ? (
                <Row className={"row_for_about_small m-0"}>
                  <Col
                    xl={{ span: 6, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                    sm={{ span: 10, offset: 1 }}
                    xs={{ span: 10, offset: 1 }}
                  >
                    <div className={"small_section_for_text"}>
                      <p className={"title"}>{items[`title_${intl.locale}`]}</p>
                      <p
                        className={"description"}
                        dangerouslySetInnerHTML={{
                          __html: items[`description_${intl.locale}`],
                        }}
                      >
                        {}
                      </p>
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 6, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                    sm={{ span: 10, offset: 1 }}
                    xs={{ span: 10, offset: 1 }}
                  >
                    <div className={"small_section_for_img"}>
                      <img
                        src={`${NET.APP_URL}/public/image/${items.about_images}`}
                        className={"img-fluid"}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className={"row_for_about_small"}>
                  <Col
                    xl={{ span: 4, offset: 1 }}
                    lg={{ span: 4, offset: 1 }}
                    md={{ span: 4, offset: 1 }}
                    sm={{ span: 6, offset: 3 }}
                    xs={{ span: 6, offset: 3 }}
                  >
                    <div className={"small_section_for_img"}>
                      <img
                        src={`${NET.APP_URL}/public/image/${items.about_images}`}
                        className={"img-fluid"}
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 6, offset: 1 }}
                    lg={{ span: 6, offset: 1 }}
                    md={{ span: 6, offset: 1 }}
                    sm={{ span: 10, offset: 1 }}
                    xs={{ span: 10, offset: 1 }}
                  >
                    <div className={"small_section_for_text"}>
                      <p className={"title"}>{items[`title_${intl.locale}`]}</p>
                      <p
                        className={"description"}
                        dangerouslySetInnerHTML={{
                          __html: items[`description_${intl.locale}`],
                        }}
                      ></p>
                    </div>
                  </Col>
                </Row>
              );
          })}
      </Container>
    </>
  );
}
