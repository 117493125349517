import React from 'react';

function Interier() {
    return(
        <>
        <h1>Interier</h1>
        </>
    )
}
export default Interier

