import React ,{useState , useEffect} from "react";
import {Container,Row,Col} from 'react-bootstrap';
import axios from "axios";
import {useParams} from 'react-router-dom';
import '../../assets/css/representation/style.css'
import { useIntl } from "react-intl";
function BrandsMap(){
  let { id } = useParams();
  const [home_brand_images, sethome_brand_images] = useState([]);
  const [new_brands_single, setnew_brands_single] = useState([]);
  const [new_brands_category, setnew_brands_category] = useState([]);
  const intl = useIntl()

  useEffect(() => {
      // axios.get(`${NET.APP_URL}/api/home_brand_images`).then(
      axios.get(`https://admin.caron.am/api/home_brand_images`).then(
          res => sethome_brand_images(res.data),
          
      )
  }, [])
  
  useEffect(() => {
      // axios.get(`${NET.APP_URL}/api/home_brand_images`).then(
      axios.get(`https://admin.caron.am/api/new_brands_single`).then(
          res => setnew_brands_single(res.data),
          
      )
  }, [])
  
  useEffect(() => {
      // axios.get(`${NET.APP_URL}/api/home_brand_images`).then(
      axios.get(`https://admin.caron.am/api/new_brands_category`).then(
          res => setnew_brands_category(res.data),
          
      )
  }, [])
  return(
    <>
    <Container>
      <Row>
        <Col xl={12} className={'brand_title_center'}>
            {home_brand_images.map((item,index) => {
                if(item.id == id)
                  return <div>
                            {item.title}
                              {new_brands_single.map((items,index) => {
                                  if(items.brand_name == item.title)
                                      return <div className={'brand_description'}>
                                        {
                                          items[`desc_${intl.locale}`]
                                        }
                                        {/* <ul className={'brand_list'}> */}
                                       <Container className="mt-5">
                                          <Row>

                                         
                                        {new_brands_category.map((itemss,index) => {

              
            if(itemss.brand_unique == item.title)
              return <Col xl={3} lg={3} md={4} sm={6} xs={6} className={'text-light'}>
                
                  <div>
                  <img src={`https://admin.caron.am/public/image/${itemss.category_images}`} className={'img-fluid'} alt={ itemss.category_list }/>
                  </div>
                  <div className="mt-3">
                     {itemss.category_list}                     
                  </div>
                
     
              </Col>
              
            })}
                {/* </ul> */}
                </Row>
                                        </Container> 
          </div>
           
           
        })}
          </div>
          
          
        })}
        </Col>
        <Col xl={12}>
       
        </Col>
      </Row>
    </Container>
    </>
  )
}
export default BrandsMap 
