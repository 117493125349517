import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import HomeSlider from "./HomeSlider";
import { Col, Container, Row } from "react-bootstrap";
import CaronLogo from "../../assets/img/Home/nor.jpg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ProductImg from "../../assets/img/Home/product.jpg";
import "../../assets/css/home/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NET from "../Url/url";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PartnersImg from "../../assets/img/Home/subbanner-01.jpg";
import News1 from "../../assets/img/Home/banner-1.jpg";
import { useIntl } from "react-intl";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

function Home() {
  const [home_brand_images, sethome_brand_images] = useState([]);
  useEffect(() => {
    // axios.get(`${NET.APP_URL}/api/home_brand_images`).then(
    axios
      .get(`https://admin.caron.am/api/home_brand_images`)
      .then((res) => sethome_brand_images(res.data));
  }, []);
  const [caron_about, setcaron_about] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/caron_about`)
      .then((res) => setcaron_about(res.data));
  }, []);
  const [caron_products, setcaron_products] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/caron_product`)
      .then((res) => setcaron_products(res.data));
  }, []);

  const [caron_news, setcaron_news] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/caron_news`)
      .then((res) => setcaron_news(res.data));
  }, []);
  const sortedNews = useMemo(() => {
    if (!caron_news) return [];
    return caron_news
      .slice()
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }, [caron_news]);

  // Sort the news items
  // const sortedNews = caron_news ? sortNewsByDateDesc(caron_news) : [];

  const [caron_partner, setcaron_partner] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/caron_partner`)
      .then((res) => setcaron_partner(res.data));
  }, []);
  const intl = useIntl();
  return (
    <>
      <Container fluid id={"representation_section"}>
        <Container>
          <Row>
            <Col>
              <h4 className={"text-center rep_title"}>
                <FormattedMessage id={"nav_forth"} />
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 12, offset: 0 }}>
              <div className={"for_represetation_divs"}>
                {home_brand_images &&
                  home_brand_images.map((itm, index) => {
                    return (
                      <a
                        href={`https://shop.caron.am/brand/${itm.title}`}
                        target="_blank"
                      >
                        <div key={index} className="circle_brand">
                          <img
                            src={`${NET.APP_URL}/public/image/${itm.image}`}
                            className={"img-fluid"}
                            alt={itm.title}
                          />
                        </div>
                      </a>
                    );
                  })}
              </div>
            </Col>
            <Link to={"/representation"} className={"prev-link"}>
              <FormattedMessage id={"see_more"} />
            </Link>
          </Row>
        </Container>
      </Container>
      <HomeSlider />

      {caron_about &&
        caron_about.map((items, index) => {
          if (items.location == "home_page")
            return (
              <div id={"home-section-about_about"}>
                <Container fluid className={"m-0 about_height_container"}>
                  <Row key={index}>
                    <Col
                      xl={{ span: 3, offset: 2 }}
                      lg={{ span: 3, offset: 2 }}
                      md={{ span: 3, offset: 2 }}
                      sm={{ span: 4, offset: 3 }}
                      xs={{ span: 6, offset: 3 }}
                      className={"left_section"}
                    >
                      <img
                        src={`${NET.APP_URL}/public/image/${items.about_images}`}
                        className={"img-fluid w-100"}
                        alt={items.title}
                      />
                    </Col>
                    <Col
                      xl={{ span: 5, offset: 1 }}
                      lg={{ span: 5, offset: 1 }}
                      md={{ span: 5, offset: 1 }}
                      sm={{ span: 6, offset: 3 }}
                      xs={{ span: 10, offset: 1 }}
                      className={"right_section"}
                    >
                      <h3>
                        <FormattedMessage id={"nav_second"} />
                      </h3>
                      <p>{items[`description_${intl.locale}`]}</p>
                      <Link to={"/about"} className={"prev-link"}>
                        <FormattedMessage id={"see_more"} />
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
        })}

      {/* <Container fluid id={"product_section"}>
                <Row>
                    <Col xl={12}>
                        <h3 className={'product_title'}>
                            <FormattedMessage id={'nav_third'} />
                        </h3>
                    </Col>
                </Row>
                <Row>

                </Row>
                <Container>
                    <Row>

                        {
                            caron_products && caron_products.slice(0, 3).map(
                                (ittem,index) => {
                                    return  <Col xl={{span:4,offset:0}} lg={{span:4,offset:0}} md={{span:6,offset:3}} sm={{span:8,offset:2}} xs={{span:10,offset:1}}>
                                    <div className="for_product_small">
                                        <div className="for_product_small_img">
                                            <img src={`${NET.APP_URL}/public/image/${ittem.product_pictures}`} className={'img-fluid'} alt=""/>
                                        </div>
                                        <div className="for_product_small_text">
                                            <h3>
                                                {ittem[`title_${intl.locale}`]}
                                            </h3>
                                            <p>
                                            {ittem[`description_${intl.locale}`]}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                }
                            )
                        }        
                    </Row>
                    <Row>
                        <Col xl={12} className={'for-link'}>
                            <Link to={'/product'} className={'prev-link'}>
                                <FormattedMessage id={'see_more'} />
                            </Link>
                        </Col>
                    </Row>
                </Container>

            </Container> */}
      <Container fluid id={"partners_section"}>
        <Container>
          <Row>
            <Col xl={12}>
              <h3 className={"big-title"}>
                <FormattedMessage id={"nav_sixth"} />
              </h3>
            </Col>
          </Row>
          <Row className={"partners-block-row"}>
            {/* {caron_partner &&
              caron_partner.slice(0, 3).map((iitem, index) => {
                return (
                  <Col
                    xl={{ span: 2, offset: 0 }}
                    lg={{ span: 2, offset: 0 }}
                    md={{ span: 4, offset: 3 }}
                    sm={{ span: 6, offset: 2 }}
                    xs={{ span: 8, offset: 2 }}
                  >
                    <div className={"partners-parts"}>
                      <div className="for_partners_img">
                        <img
                          src={`${NET.APP_URL}/public/image/${iitem.partner_image}`}
                          className={"img-fluid"}
                          alt={iitem.partner_image}
                          id={"partner-image-first"}
                        />
                      </div>
                      <div className="for_partners_name">
                        <p>{iitem[`title_${intl.locale}`]}</p>
                      </div>
                      <div className="for_partners_name second">
                        <p>{iitem[`address_${intl.locale}`]}</p>
                      </div>
                    </div>
                  </Col>
                );
              })} */}
            <Col xl={12} className={"section_for_partners_accardion"}>
              <Tabs>
                <TabList>
                  <Tab>
                    <FormattedMessage id={"erevan"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"kotayq"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"armavir"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"ararat"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"gexarquniq"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"aragacotn"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"shirak"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"vaioc"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"tavush"} />
                  </Tab>
                  <Tab>
                    <FormattedMessage id={"lori"} />
                  </Tab>
                </TabList>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner.slice(0, 3).map((item, index) => {
                          if (item.location == "erevan")
                            return (
                              <Col
                                xl={{ span: 4, offset: 0 }}
                                lg={{ span: 3, offset: 0 }}
                                md={{ span: 6, offset: 0 }}
                                sm={{ span: 6, offset: 3 }}
                                xs={{ span: 8, offset: 2 }}
                                key={index}
                              >
                                <div className={"partners-parts"}>
                                  <div className="for_partners_img">
                                    <img
                                      src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                      className={"img-fluid"}
                                      alt={item.partner_image}
                                      id={"partner-image-first"}
                                    />
                                  </div>
                                  <div className="for_partners_name">
                                    <p>{item[`title_${intl.locale}`]}</p>
                                  </div>
                                  <div className="for_partners_name second mt-0">
                                    <p>{item[`address_${intl.locale}`]}</p>
                                  </div>
                                </div>
                              </Col>
                            );
                        })}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "kotayq")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "armavir")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "ararat")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "gexarquniq")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "aragacotn")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "shirak")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "vayots")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "tavush")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel>
                  <Container fluid className={"border py-5"}>
                    <Row>
                      {caron_partner &&
                        caron_partner
                          .filter((item) => item.location === "lori")
                          .slice(0, 3)
                          .map((item, index) => (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                    </Row>
                  </Container>
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className={"for-link"}>
              <Link to={"/partner"} className={"prev-link"}>
                <FormattedMessage id={"see_more"} />
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid id={"news_section"}>
        <Container>
          <Row>
            <Col xl={12} className={"big-title"}>
              <FormattedMessage id={"nav_fifth"} />
            </Col>
          </Row>
          <Row className={"row_for_news"}>
            {sortedNews &&
              sortedNews.slice(0, 3).map((itemm, index) => {
                return (
                  <Col
                    xl={{ span: 4, offset: 0 }}
                    lg={{ span: 4, offset: 0 }}
                    md={{ span: 6, offset: 3 }}
                    sm={{ span: 8, offset: 2 }}
                    xs={{ span: 10, offset: 1 }}
                    key={index}
                  >
                    <div className={"news-img-section"}>
                      <div className="news-img-img">
                        <img
                          src={`${NET.APP_URL}/public/image/${itemm.news_images}`}
                          className={"img-fluid"}
                          alt=""
                        />
                      </div>
                      <div className="news-img-text">
                        <h3>{itemm[`title_${intl.locale}`]}</h3>
                        {/* <p>{itemm[`description_${intl.locale}`]}</p> */}
                      </div>

                      <div className={"prev-link-div"}>
                        <Link
                          to={`/NewsList/${itemm.id}`}
                          className={"prev-link"}
                        >
                          <FormattedMessage id={"see_more"} />
                        </Link>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <Row>
            <Col xl={12} className={"for-link"}>
              <Link to={"/news"} className={"prev-link"}>
                <FormattedMessage id={"see_more"} />
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
export default Home;
