import { $api } from "..";

export default class CareService {
  static getBrands() {
    return $api.get("/care/brands");
  }

  static filterProducts(filter) {
    return $api.get("/care/products_filter", { params: filter });
  }
}
