import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Button, Form} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import "../../assets/css/news/style.css";
import PartnerImg from "../../assets/img/BecomePartner/partner.jpg";
import Modal from 'react-modal';
import {CSSTransition} from "react-transition-group";
import emailjs from 'emailjs-com';
import axios from 'axios';
import { useIntl } from 'react-intl';
import NET from '../Url/url';



function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_bow0q4w', 'template_u73kqfq', e.target, 'user_SEjLQG67dF8MSaH7z3dwW')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    e.target.reset();

}
function sendEmailPartner(e) {
    e.preventDefault();

    emailjs.sendForm('service_4uwaf5j', 'template_c86cu5n', e.target, 'user_SEjLQG67dF8MSaH7z3dwW')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    e.target.reset();

}
export default function BecomePartner() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
    const setModalIsOpenToTrue = () => {
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse = () => {
        setModalIsOpen(false)
    }
    const setModalIsOpen1ToTrue = () => {
        setModalIsOpen1(true)
    }

    const setModalIsOpen1ToFalse = () => {
        setModalIsOpen1(false)
    }
    const modalStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,.6)',
        },
    };
    const [partner_text, setpartner_text] = useState([]);
    useEffect(() => {
        axios.get(`${NET.APP_URL}/api/caron_partnerText`).then(
            res => setpartner_text(res.data),

        )
    }, [])
    console.log(partner_text);
    const intl = useIntl()
    return (
        <>
            <Container className={'section_for_become_partner'}>

                <Row className={'row_for_become_partners'}>
                    <Col xl={{span:6,offset:0}} lg={{span:6,offset:0}} md={{span:6,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}}>
                        <div className={'row_for_become_partners_description'}>
                            {
                                partner_text && partner_text.slice(0,1).map(
                                    (item,indx) => {
                                            return  <p key={indx} className={'text-light mt-5 pt-3'}>
                                                {item[`desc_${intl.locale}`]}
                                            </p>
                                    }
                                )
                            }
                        </div>
                    </Col>
                    <Col xl={{span:6,offset:0}} lg={{span:6,offset:0}} md={{span:6,offset:0}} sm={{span:8,offset:2}} xs={{span:10,offset:1}}>
                        <div>
                            <h3 className="news_title">
                                <FormattedMessage id={'nav_seventh'} />
                            </h3>
                        </div>
                        <Container>
                            <Row>
                                <Col xl={{span:6,offset:3}}>
                                    <div className={'section_for_partner_modal'}>
                                        <div className={'partner_modal_img'}>
                                            <img src={PartnerImg} className={'img-fluid'} alt=""/>
                                        </div>
                                        <div className={'partner_modal_btn'}>
                                            <Button onClick={setModalIsOpen1ToTrue}>
                                                <FormattedMessage id={'button_value'}  />
                                            </Button>
                                            <CSSTransition
                                                in={setModalIsOpen1ToTrue}
                                                timeout={300}
                                                classNames="dialog"
                                            >
                                                <Modal
                                                    closeTimeoutMS={500}
                                                    isOpen={modalIsOpen1}
                                                    style={modalStyles}
                                                >
                                                    <div className={'div_for_btn_close'}>
                                                        <button className={'close_btn'} onClick={setModalIsOpen1ToFalse} style={{ float: 'right' , background:'transparent', border:'none' , color:"#ffae03" }} >X</button>
                                                    </div>
                                                    <Container className={'mt-3'}>
                                                        <Row className={'row_for_modal_title'}>
                                                            <Col xl={{span:10,offset:1}} className={'title'}>
                                                                <FormattedMessage id={'form_value1'} />
                                                            </Col>
                                                        </Row>
                                                        <Row className={'row_for_form'}>
                                                            <Col xl={{span:10,offset:1}}>
                                                                <Form onSubmit={sendEmail}>
                                                                <Form.Group controlId='formBasicEmail'>
                                                                        <Form.Label for='for_email'>
                                                                            <FormattedMessage id={'label_hvhh'} />
                                                                        </Form.Label>
                                                                        <Form.Control type='text' id={'for_email'} required placeholder={'02020202'} name={'partner_hvhh'} />
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                        <Form.Label for={'for_education'}>Իրավաբանական Անձ</Form.Label>
                                                        {/* <Form.Control as="select" id={'for_education'} name={'iravabanakan'} required>
                                                            <option value="ԱՁ">ԱՁ</option>
                                                            <option value="ՍՊԸ">ՍՊԸ</option>
                                                            <option value="ՍՊԸ">ԲԲԸ</option>
                                                            <option value="ՍՊԸ">ՓԲԸ</option>
                                                        </Form.Control> */}
                                                    <Form.Control type='text' id={'name_lastname'} required placeholder={'ՔԱՌՈՆ ՍՊԸ'} name={'work'} />
                                                    </Form.Group>
                                                                    <Form.Group>
                                                                        <Form.Label for='name_lastname'>
                                                                            <FormattedMessage id={'name_lastname'} />
                                                                        </Form.Label>
                                                                        <Form.Control type='text' id={'name_lastname'} required placeholder={'Անի Ադամյան'} name={'partner_name_lastname'} />
                                                                    </Form.Group>
                                                                    
                                                                    <Form.Group>
                                                                        <Form.Label for='for_phone'>
                                                                            <FormattedMessage id={'label_tel'} />
                                                                        </Form.Label>
                                                                        <Form.Control type='number' id={'for_phone'} required placeholder={'+374 00 00 00 00'} name={'partner_number'} />
                                                                    </Form.Group>

                                                                    <Form.Group controlId='formBasicEmail'>
                                                                        <Form.Label for='for_email'>
                                                                            <FormattedMessage id={'label_email'} />
                                                                        </Form.Label>
                                                                        <Form.Control type='email' id={'for_email'} placeholder={'example@mail.ru'} name={'partner_email'} />
                                                                    </Form.Group>
                                                                    <Form.Group controlId='formBasicEmail'>
                                                                        <Form.Label for='for_email'>
                                                                            <FormattedMessage id={'label_address'} />
                                                                        </Form.Label>
                                                                        <Form.Control type='text' id={'for_email'} required placeholder={'Ք. Երևան Կոմիտաս 26'} name={'partner_address'} />
                                                                    </Form.Group>
                                                                    
                                                                    {/*<Form.Group>*/}
                                                                    {/*    <Form.Label for={'for_education'}>*/}
                                                                    {/*        <FormattedMessage id={'label_type'} />*/}
                                                                    {/*    </Form.Label>*/}
                                                                    {/*    <Form.Control as="select" id={'for_education'} name={'partner_type'}>*/}
                                                                    {/*        <option value="Ավտոպահեստամասեր">*/}
                                                                    {/*            Ավտոպահեստամասեր*/}
                                                                    {/*        </option>*/}
                                                                    {/*        <option value="Մթերային">*/}
                                                                    {/*            Մթերային Խանութ*/}
                                                                    {/*        </option>*/}
                                                                    {/*        <option value="Ավտոտեխսպասարկում">*/}
                                                                    {/*            Ավտոտեղսպասարկում*/}
                                                                    {/*        </option>*/}
                                                                    {/*    </Form.Control>*/}
                                                                    {/*</Form.Group>*/}
                                                                    <div className={'partner_modal_btn'}>
                                                                        <Button type={'submit'}>
                                                                            <FormattedMessage id={'form_submit'}  />
                                                                        </Button>
                                                                    </div>

                                                                </Form>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Modal>
                                            </CSSTransition>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>
            </Container>
        </>
    )
}
// export default BecomePartner
