import { $api } from "..";

export default class BrushService {
  static getBrands() {
    return $api.get("/brush/brands");
  }

  static getTypes() {
    return $api.get("/brush/types");
  }

  static filterProducts(filter) {
    return $api.get("/brush/products_filter", { params: filter });
  }
}
