import React from "react";

import "./Checkbox.css";

export const Checkbox = ({
  checkboxProps = {},
  checked,
  id,
  name,
  onChange,
  label,
  value,
  isDisabled,
}) => {
  return (
    <div className="innerWrapper">
      <input
        checked={checked}
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
        {...checkboxProps}
      />
      {label && (
        <label className="label" htmlFor={id}>
          <span>{label}</span>
        </label>
      )}
    </div>
  );
};
