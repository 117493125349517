import { $api } from "..";

export default class LampService {
  static getBrands() {
    return $api.get("/lamp/brands");
  }

  static getTypes() {
    return $api.get("/lamp/types");
  }

  static getSlots() {
    return $api.get("/lamp/slots");
  }

  static getProducts() {
    return $api.get("/lamp/products");
  }

  static filterProducts(filter) {
    return $api.get("/lamp/products_filter", { params: filter });
  }
}
