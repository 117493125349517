import React, { useState, useEffect } from "react";
import axios from "axios";
import NET from "../Url/url";
import { useIntl } from "react-intl";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import "../../assets/css/news/style.css";
import "../../assets/js/script";
import { FormattedMessage } from "react-intl";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
function Partner() {
  const [caron_partner, setcaron_partner] = useState([]);
  useEffect(() => {
    axios
      .get(`${NET.APP_URL}/api/caron_partner`)
      .then((res) => setcaron_partner(res.data));
  }, []);
  console.log(caron_partner);

  const intl = useIntl();
  return (
    <>
      <Container>
        <Row>
          <Col xl={12}>
            <h3 className="news_title">
              <FormattedMessage id={"nav_sixth"} />
            </h3>
          </Col>
        </Row>
      </Container>
      <Container className={"section_for_partners_accardion"}>
        <Row>
          {/* <Col xl={12}>
            <Tabs>
              <TabList>
                <Tab>
                  <FormattedMessage id={"erevan"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"kotayq"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"armavir"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"ararat"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"gexarquniq"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"aragacotn"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"shirak"} />
                </Tab>
              </TabList>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "erevan")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                    id={"partner-image-first"}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second mt-0">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "kotayq")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "armavir")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "ararat")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "gexarquniq")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "aragacotn")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "shirak")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
            </Tabs>
          </Col> */}
          <Col xl={12} className={"section_for_partners_accardion"}>
            <Tabs>
              <TabList>
                <Tab>
                  <FormattedMessage id={"erevan"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"kotayq"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"armavir"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"ararat"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"gexarquniq"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"aragacotn"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"shirak"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"vaioc"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"tavush"} />
                </Tab>
                <Tab>
                  <FormattedMessage id={"lori"} />
                </Tab>
              </TabList>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner.map((item, index) => {
                        if (item.location == "erevan")
                          return (
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 3, offset: 0 }}
                              md={{ span: 6, offset: 0 }}
                              sm={{ span: 6, offset: 3 }}
                              xs={{ span: 8, offset: 2 }}
                              key={index}
                            >
                              <div className={"partners-parts"}>
                                <div className="for_partners_img">
                                  <img
                                    src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                    className={"img-fluid"}
                                    alt={item.partner_image}
                                    id={"partner-image-first"}
                                  />
                                </div>
                                <div className="for_partners_name">
                                  <p>{item[`title_${intl.locale}`]}</p>
                                </div>
                                <div className="for_partners_name second mt-0">
                                  <p>{item[`address_${intl.locale}`]}</p>
                                </div>
                              </div>
                            </Col>
                          );
                      })}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "kotayq")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "armavir")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "ararat")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "gexarquniq")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "aragacotn")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "shirak")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "vayots")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "tavush")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <Container fluid className={"border py-5"}>
                  <Row>
                    {caron_partner &&
                      caron_partner
                        .filter((item) => item.location === "lori")

                        .map((item, index) => (
                          <Col
                            xl={{ span: 4, offset: 0 }}
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 6, offset: 0 }}
                            sm={{ span: 6, offset: 3 }}
                            xs={{ span: 8, offset: 2 }}
                            key={index}
                          >
                            <div className={"partners-parts"}>
                              <div className="for_partners_img">
                                <img
                                  src={`${NET.APP_URL}/public/image/${item.partner_image}`}
                                  className={"img-fluid"}
                                  alt={item.partner_image}
                                />
                              </div>
                              <div className="for_partners_name">
                                <p>{item[`title_${intl.locale}`]}</p>
                              </div>
                              <div className="for_partners_name second">
                                <p>{item[`address_${intl.locale}`]}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                  </Row>
                </Container>
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Partner;
