import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useFreshener } from "../../../http/freshener-service/use-freshener";
import ProductItem from "../../../components/ProductItem/ProductItem";
import { Checkbox } from "../../../components/Checkbox/Checkbox";

function Hoter() {
  const [filter, setFilter] = useState({
    brand: [],
    type: [],
    smell: [],
  });

  const { isLoading, brands, types, smells, products, isProductsLoading } =
    useFreshener(filter);

  const onChangeBrand = (e) => {
    setFilter((prev) => {
      const cp = new Set(prev.brand);
      if (cp && cp.has(e.target.value)) {
        cp.delete(e.target.value);
      } else {
        cp.add(e.target.value);
      }
      return {
        ...prev,
        brand: [...new Set(cp)],
      };
    });
  };

  const onChangeType = (e) => {
    setFilter((prev) => {
      const cp = new Set(prev.type);
      if (cp && cp.has(e.target.value)) {
        cp.delete(e.target.value);
      } else {
        cp.add(e.target.value);
      }
      return {
        ...prev,
        type: [...new Set(cp)],
      };
    });
  };

  const onChangeSmell = (e) => {
    setFilter((prev) => {
      const cp = new Set(prev.smell);
      if (cp && cp.has(e.target.value)) {
        cp.delete(e.target.value);
      } else {
        cp.add(e.target.value);
      }
      return {
        ...prev,
        smell: [...new Set(cp)],
      };
    });
  };

  useEffect(() => {
    return () => {
      setFilter({
        brand: [],
        type: [],
        smell: [],
      });
    };
  }, []);

  return (
    <Container className={"section_for_goodier_vasxod"} fluid>
      {isLoading ? (
        <Spinner animation="border" variant="warning" />
      ) : (
        <>
          <Row style={{ margin: "15px 0" }}>
            {brands?.map((el) => (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-evently",
                  alignItems: "center",
                }}
                className={"text-light"}
              >
                <Checkbox
                  id={el.fresh_brand}
                  value={el.fresh_brand}
                  label={el.fresh_brand}
                  onChange={onChangeBrand}
                />
              </Col>
            ))}
          </Row>
          <Row style={{ margin: "15px 0" }}>
            {types?.map((el) => (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-evently",
                  alignItems: "center",
                }}
                className={"text-light"}
              >
                <Checkbox
                  id={el.fresh_type}
                  value={el.fresh_type}
                  label={el.fresh_type}
                  onChange={onChangeType}
                />
              </Col>
            ))}
          </Row>
          <Row style={{ margin: "15px 0" }}>
            {smells?.map((el) => (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-evently",
                  alignItems: "center",
                }}
                className={"text-light"}
              >
                <Checkbox
                  id={el.fresh_smell}
                  value={el.fresh_smell}
                  label={el.fresh_smell}
                  onChange={onChangeSmell}
                />
              </Col>
            ))}
          </Row>
          {!isProductsLoading ? (
            <Row style={{ marginTop: "20px" }}>
              {products?.map((item) => (
                <Col>
                  <ProductItem
                    title={item.fresh_product_title_am}
                    description={item.fresh_product_desc_am}
                    image={item.fresh_product_image}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Spinner animation="border" variant="warning" />
          )}
        </>
      )}
    </Container>
  );
}
export default Hoter;
